import React from 'react'
import dyhired from '../assets/Dehydrated.png'
import pulses from '../assets/pulses.png'
const Service = () => {
    return (    
        <>
            <section className='py-5'>
                <div className="container">
                    <div className="row"> 
                
                
                        <div className="col-12 col-md-4 p-4 wow animate__animated animate__slideInLeft">
                            <div class="card p-4">
                                <div className="icon-box mx-auto fs-3 text-white bg-darkeblue"><img src={dyhired} alt="" /></div>
                                <p className='text-center fw-semibold fs-5 p-2 pb-0 m-0'>
                                    Dehydrated Products
                                </p>
                                <p className='text-center fs-10 p-3 m-0 pb-0'>Because they rehydrate in liquid, they're also easy to use in recipes. Dehydrated foods keep their nutritional value. As a lightweight, nutrient-dense option</p>
                                <div class="card-data p-4">
                                    <p className='text-center fw-semibold fs-5 p-2 pb-0 m-0'>
                                        Dehydrated Products
                                    </p>
                                    <p className='text-center fs-10 p-3 m-0 pb-0'>Because they rehydrate in liquid, they're also easy to use in recipes. Dehydrated foods keep their nutritional value. As a lightweight, nutrient-dense option, dehydrated foods are a go-to for hikers and travelers looking to save space.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 p-4 wow animate__animated animate__flipInY animate__slow">
                            <div class="card p-4">
                                <div className="icon-box mx-auto fs-3 text-white bg-darkeblue"><i class="bi bi-fire"></i></div>
                                <p className='text-center fw-semibold fs-5 p-2 pb-0 m-0'>
                                    Spices
                                </p>
                                <p className='text-center fs-10 p-3 m-0 pb-0'>
                                As India is blessed with a varied climate each of its states produce some spice or other. India is also known as the 'Land of Spices'. The major spices exported by India which available on our platform.
</p>
                                <div class="card-data p-4"> 
                                    <p className='text-center fw-semibold fs-5 p-2 pb-0 m-0'>
                                        Spices
                                    </p>
                                    <p className='text-center fs-10 p-3 m-0 pb-0'>
                                    As India is blessed with a varied climate each of its states produce some spice or other. India is also known as the 'Land of Spices'. The major spices exported by India which available on our platform.
</p>    
                                 </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 p-4 wow animate__animated animate__slideInRight">
                            <div class="card p-4">
                                <div className="icon-box mx-auto fs-3 text-white bg-darkeblue"><img src={pulses} alt="" /></div>
                                <p className='text-center fw-semibold fs-5 p-2 pb-0 m-0'>
                                Pulses

                                </p>
                                <p className='text-center fs-10 p-3 m-0 pb-0'>
                                Pulses are nutritional powerhouses among all the protein sources as they have zero cholesterol, high content of iron, gluten-free, rich in minerals and vitamins, low-fat content, and an important source of dietary fibre.</p>
                                <div class="card-data p-4"> 
                                    <p className='text-center fw-semibold fs-5 p-2 pb-0 m-0'>
                                    Pulses

                                    </p>
                                    <p className='text-center fs-10 p-3 m-0 pb-0'>Pulses are nutritional powerhouses among all the protein sources as they have zero cholesterol, high content of iron, gluten-free, rich in minerals and vitamins, low-fat content, and an important source of dietary fibre.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>                  
        </>
    )
}

export default Service