import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import logo from '../assets/Agri world.jpg'
import { useState } from 'react';
const Header = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let script=`$(window).scroll(function () {
        if ($(this).scrollTop() > 10) {
            $("header").addClass("fixed")
        }
        else {
            $("header").removeClass("fixed")
        }
    })`
    return (
        <>
            <header className='wow animate__animated animate__fadeInDown animate__delay-2s'>
                <Navbar collapseOnSelect expand="lg" className="bg-body-white">
                    <Container className='bg-white rounded-2'>
                        <Navbar.Brand className='col-3 col-md-2'><Link to='/'><img src={logo} alt="" /></Link></Navbar.Brand>
                        <Navbar.Toggle href="#" aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="#" className='header-hover'><Link to='/' className='fw-semibold text-uppercase'>home</Link></Nav.Link>
                                <Nav.Link className='header-hover'><Link className='fw-semibold text-uppercase'>OUR COMPANY <i class="bi bi-caret-down-fill"></i></Link>
                                    <div class="drop-down">
                                        <ul>
                                            <li><Nav.Link href='#'><Link to='/About_Us' className='text-uppercase'>about us</Link></Nav.Link></li>
                                            <li><Nav.Link href='#'><Link to='/Accreditation' className='text-uppercase'>Accreditation</Link></Nav.Link></li>
                                        </ul>
                                    </div>
                                </Nav.Link>
                                <Nav.Link className='header-hover'><Link className='fw-semibold text-uppercase'>pRODUCT <i class="bi bi-caret-down-fill"></i></Link>
                                    <div class="drop-down">
                                        <ul>
                                            <li><Nav.Link href='#'><Link to='/PULSES' className='text-uppercase'>PULSES</Link></Nav.Link></li>
                                            <li><Nav.Link href='#'><Link to='/Spices' className='text-uppercase'>Spices</Link></Nav.Link></li>
                                            <li><Nav.Link href='#'><Link to='/grain' className='text-uppercase'>Grain</Link></Nav.Link></li>
                                            <li><Nav.Link href='#'><Link to='/oil' className='text-uppercase'>oil seed</Link></Nav.Link></li>
                                            <li><Nav.Link href='#'><Link to='/Dehydrated' className='text-uppercase'>Dehydrated </Link></Nav.Link></li>
                                            <li><Nav.Link href='#'><Link to='/all_prodcuts' className='text-uppercase'>All prodcuts</Link></Nav.Link></li>
                                            <li><Nav.Link href='#'><Link to='/other' className='text-uppercase'>other prodcuts</Link></Nav.Link></li>
                                        </ul>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href='#' className='header-hover'><Link to='/Team' className='fw-semibold text-uppercase'>our team</Link></Nav.Link>
                                <Nav.Link href='#' className='header-hover'><Link to='/Trade_Fair' className='fw-semibold text-uppercase'>TRADE FAIR PARTICIPATION</Link></Nav.Link>
                                <Nav.Link href='#' className='header-hover'><Link to='/Contact' className='fw-semibold text-uppercase'>contact us</Link></Nav.Link>
                            </Nav>
                            <Nav>
                                <button onClick={handleShow} className='text-secondary border-0 bg-transparent'> <i className="bi bi-search fw-bolder"></i></button>
                                <Modal
                                    show={show}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                    centered
                                    style={{backgroundColor:"#00000066"}}
                                >
                                    <Modal.Body 
                                    style={{backgroundColor:"rgba(0, 0, 0, 0.7)"}}>
                                        <input type="text" placeholder='search ...' className='bg-transparent col-11 text-white text-capitalize border-0 p-2 border-bottom-2' />
                                        <button onClick={handleClose} className='ms-3 fw-bold border-0 bg-transparent'><i class="bi bi-x-lg text-white fw-bold"></i></button>
                                    </Modal.Body>
                                </Modal>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
                <script src="https://code.jquery.com/jquery-3.7.1.js" integrity="sha256-eKhayi8LEQwp4NKxN+CfCh+3qOVUtJn3QNZ0TciWLP4=" crossorigin="anonymous"></script>
                <script>{script}</script>
        </>
    )
}

export default Header