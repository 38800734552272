    import { Col, Container, Row } from 'react-bootstrap'

const Header_contact = () => {
    
    return (
        <section className='bg-primary pt-2 pb-3 wow animate__animated animate__fadeInDown'>
            <Container>
                <Row>   
                    <Col sm={12} md={8}>
                        <div className='d-flex'>
                            <a href={`tel:+917817815050`} className='col-5 p-0 text-white border border-0 border-md-2 border-bottom-0 border-start-0 border-top-0'><a href="https://wa.me/918306083041?text=&amp;text=Hi! MR JIGNESH
 " target='_blank' className='pe-1 text-white'><i class="bi bi-whatsapp"></i></a> +91 78178 15050</a>
                            <a href="mailto:agriworldexim@gmail.com" className='col-7 p-0 text-whzuuite text-end text-md-center' >agriworldexim@gmail.com</a>
                        </div>
                    </Col>                  
                    <Col sm={12} md={4} >
                        <div className='d-flex justify-content-end'>
                            <i class="bi text-white px-2 bi-linkedin"></i>
                            <a href="https://twitter.com/agriworldexim" target="_blank"><i class="bi text-white px-2 bi-twitter"></i></a>
                            <a href="https://www.instagram.com/Agriworld_exim" target="_blank"><i class="bi text-white px-2 py-3 bi-instagram"></i></a>
                            <a href="https://www.facebook.com/Agriworld_exim" target="_blank"><i class="bi text-white px-2 bi-facebook"></i></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>  
    )
}

export default Header_contact
