import React from 'react'
import Contact_from from '../components/Contact_from'

const Contact = () => {
  return (
    <>
    <Contact_from/>
    </>
  )
}

export default Contact