
import React, { useEffect, useState } from 'react'
import Gram from '../assets/p11.jpg'
import Pigeon from '../assets/p12.jpg'
import Pulses from '../assets/p13.jpg'
import pigeon from '../assets/top-view-raw-uncooked-red-lentils-bowl-with-wooden-spoon-green-background-with-copy-space.jpg'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Banner from '../assets/sinlgletitle-bg.png'

const Beverages = () => {
  let [arr, setArr] = useState([])
  let [data, setdata] = useState([])

  
  
  
  let p11 = {
    id: 11,
    title: 'Gram Lentils',
    img: Gram,
    Quantity_type: "g/kg",
    Form_Factor: 'Pulses',
    Container_Type: 'Box / pouch',
    prod_num: '10011 ',
  }
  let p12 = {
    id: 12,
    title: 'Pigeon Peas & Lentils (Pulses)',
    img: Pigeon,
    Quantity_type: "g/kg",
    Form_Factor: 'Pulses',
    Container_Type: 'Box / pouch',
    prod_num: '10011 ',
  }
  let p13 = {
    id: 13,
    title: 'Gram (Pulses)',
    img: Pulses,
    Quantity_type: "g/kg",
    Form_Factor: 'Pulses',
    Container_Type: 'Box / pouch',
    prod_num: '10011 ',
  }
  let p15 = {
    id: 15,
    title: 'Pigeon Peas (Pulses)',
    img: pigeon,
    Quantity_type: "g / kg",
    Form_Factor: 'Pulses',
    Container_Type: 'container / bottel',
    prod_num: '9410 ',
  }
  useEffect(() => {
    setArr([p11, p12, p13])
  }, [])
  useEffect(() => {
    setdata(arr)
  }, [arr])
  return (
    <>
      <section className='col-12'>
        <img src={Banner} className='position-relative' />
        <p className='fs-1 d-none d-md-block position-absolute text-center start-center text-uppercase'>our Pulses prodcuts</p>
      </section>
      <Container>
        <Row className='justify-content-center'>
          {
            data.map((ele) => {
              return (
                <>
                  <Col sm={12} md={6} lg={3} className='p-2 py-4 ' >
                    <div className='border border-1 rounded overflow-hidden shadow'>
                      <Card.Img variant="top" className='zoom-hover' src={ele.img} style={{ height: '180px' }} />
                      <Card.Body className='p-3'>
                        <Card.Title className='py-2 text-center'>{ele.title}</Card.Title>
                        <Nav.Link href='#'>
                          <Link to={`/prodcuts/${ele.id}`} className='text-black d-flex justify-content-center'>
                            <button className='btn col-6 btn-outline-primary py-2 mx-auto text-uppercase'>read more</button>
                          </Link>
                        </Nav.Link>
                      </Card.Body>
                    </div>
                  </Col>
                </>
              )
            })
          }
        </Row>
      </Container>
    </>
  )
}

export default Beverages