import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Zoom, } from 'swiper/modules';
import b1 from '../assets/b10.jpg'
import b2 from '../assets/b9.jpg'
import b3 from '../assets/bb1.jpg'
import b4 from '../assets/bb2.jpg'
import b5 from '../assets/bb3.jpg'
import b6 from '../assets/bb4.jpg'
const Banner = () => {
    return (
        <>
            <section className='banner'>
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                    }}
                    centeredSlides={true}
                    zoom={true}
                    loop={true}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    modules={[Zoom, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div>
                            <img src={b1} className='banner-img-slider'/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <img src={b2} className='banner-img-slider'/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <img src={b3} className='banner-img-slider'/>
                        </div>
                    </SwiperSlide><SwiperSlide>
                        <div>
                            <img src={b4} className='banner-img-slider'/>
                        </div>
                    </SwiperSlide><SwiperSlide>
                        <div>
                            <img src={b5} className='banner-img-slider'/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <img src={b6} className='banner-img-slider'/>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>
        </>
    )
}

export default Banner