import React, { useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import PhoneInput from 'react-phone-number-input'

const Contact_from = () => {
  let [btn, setbtn] = useState("send")
  let [name, setname] = useState("")
  let [mail, setmail] = useState("")
  let [message, setmessage] = useState("")
  let [value, setValue] = useState("")
  function Submit(e) {
    e.preventDefault()
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    if (mail.length == 0) {
      alert('please sir , enter the mail')
    }
    else if (mail.length == 0) {
      alert('please sir , enter the mail')
    }
    else if (name.length <= 2 || name.length == 0) {
      alert('please sir , enter your name')
    }
    else if (message.length <= 2 || message.length == 0) {
      alert('please sir , enter write your message')
    }
    else {
      fetch(
        'https://script.google.com/macros/s/AKfycbwHmkzdqRWHnX4Ay-lrFKwNYsuVhzOyDbvl5s_vLpYQQCmd_WKnccXRvM8B-00kvjGAgw/exec',
        {
          method: "POST",
          body: formDatab,value
        }
      )
      alert("Thank you ! your form is submitted successfully.i will contact you shortly")
      setValue('')
      setname('')
      setmail('')
      setmessage('')
    }
  }
  return (
    <>
      <section className='py-5'>
        <Container>
          <Col sm={8} className='contact-bg py-3 rounded mx-auto shadow-team'>
            <p className='text-uppercase text-center m-0 position-relative fw-bold fs-4 text-primary'>Share<span className='text-white fw-bold'> Your
            </span> <span className='text-success'>Requirement</span></p>
            <form onSubmit={(e) => Submit(e)} className='p-5'>
              <input type="text" placeholder='name' name='name' value={name} onChange={(e) => setname(e.target.value)} className='bg-transparent  col-12 my-3 p-2 text-capitalize contact-border rounded' />
              <div className="row my-3" >
                <div className='col-12 my-3 col-md-6 pe-1'>
                  <div class="form-group">
                    <PhoneInput
                      international
                      defaultCountry="IN"
                      value={value}
                      onChange={setValue} 
                      required
                      placeholder='Phone number'
                      className='bg-transparent contact-border text-capitalize col-12 p-2 rounded'/>
                      <input type="text" name="number" value={value} className='d-none' />
                  </div>
                </div>
                <div className='col-12 my-3 col-md-6 pe-1'>
                  <input type="email" placeholder='email' name='email' value={mail} onChange={(e) => setmail(e.target.value)} className='bg-transparent  contact-border text-capitalize col-12 p-2 rounded' />
                </div></div>
              <textarea placeholder='message' rows="10" name='message' value={message} onChange={(e) => setmessage(e.target.value)} className='bg-transparent  contact-border text-capitalize col-12 my-3 p-2 rounded'></textarea>
              <input type="submit" className='btn btn-secondary py-2 my-3 col-5 text-capitalize' value={btn} />
            </form>
          </Col>
        </Container>
      </section>
    </>
  )
}

export default Contact_from
