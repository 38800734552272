import React, { useEffect, useState } from 'react'
import ragi from '../assets/ragi-seed.jpg'
import Peanutoil from '../assets/Peanutoil.jpeg'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import Banner from '../assets/sinlgletitle-bg.png'
import { Link } from 'react-router-dom'
const Other = () => {

    let [arr, setArr] = useState([])
    let [data, setdata] = useState([])
    
    let p3 = {
        id: 3,
        title: 'Ragi',
        img: ragi,
        Quantity_type: "g / kg",
        Form_Factor: 'Seed',
        Container_Type: 'Box / pouch',
        prod_num: '10082130',
    }
    let p6 = {
        id: 6,
        title: 'Peanut',
        img: Peanutoil,
        Quantity_type: "Litres",
        Form_Factor: 'oil',
        Container_Type: 'container / bottel',
        prod_num: '151790 ',
    }
    useEffect(() => {
        setArr([p3, p6])
    }, [])
    useEffect(() => {
        setdata(arr)
    }, [arr])
    console.log(data);
    return (
        <>
            <section className='col-12'>
                <img src={Banner} className='position-relative' />
                <p className='fs-1 position-absolute text-center start-center text-uppercase'>our other prodcuts</p>
            </section>
            <Container>
                <Row className='justify-content-center'>
                    {
                        data.map((ele) => {
                            return (
                                <>
                                    <Col sm={12} md={6} lg={3} className='p-2 py-4 ' >
                                        <div className='border border-1 rounded overflow-hidden shadow'>
                                            <Card.Img variant="top" className='zoom-hover' src={ele.img} style={{ height: '180px' }} />
                                            <Card.Body className='p-3'>
                                                <Card.Title className='py-2 text-center text-capitalize'>{ele.title}</Card.Title>
                                                <Nav.Link href='#'>
                                                    <Link to={`/prodcuts/${ele.id}`} className='text-black d-flex justify-content-center'>
                                                        <button className='btn col-6 btn-outline-primary py-2 mx-auto text-uppercase'>read more</button>
                                                    </Link>
                                                </Nav.Link>
                                            </Card.Body>
                                        </div>
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
            </Container>
        </>
    )
}
export default Other;