import React from 'react'
import banner from '../assets/team-banner.png'
import Committee from '../components/Committee'
const Team = () => {
  return (
    <>
      <section>
        <img src={banner} />
      </section >
      <Committee/>
    </>
  )
}

export default Team