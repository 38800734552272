import React, { useEffect, useState } from 'react'
import Ginger_powder from '../assets/2148579397.jpg'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Cardamom from '../assets/green-cardamom-wooden-spoon-light-green_145644-42031.jpg'
import Banner from '../assets/sinlgletitle-bg.png'

const Surgical_Pro = () => {

  let [arr, setArr] = useState([])
  let [data, setdata] = useState([])
  let p1 = {
    id: 1,
    title: 'Ginger powder',
    img: Ginger_powder,
    Quantity_type: "g / kg",
    Form_Factor: 'Grain',
    Container_Type: 'Box',
    prod_num: '09101210 ',
  }
  let p8 = {
    id: 8,
    title: 'Cardamom',
    img: Cardamom,
    Quantity_type: "Grams / kg",
    Form_Factor: 'Pulses',
    Container_Type: 'Box / Count',
    prod_num: '091030 ',
  } 
  useEffect(() => {
    setArr([p1,p8])
  }, [])
  useEffect(() => {
    setdata(arr)
  }, [arr])
  return (
    <>
      <section className='col-12'>
        <img src={Banner} className='position-relative' />
        <p className='fs-1 d-none d-md-block position-absolute text-center start-center text-uppercase'>our Spices prodcuts</p>
      </section>
      <Container>
        <Row className='justify-content-center'>
          {
            data.map((ele) => {
              return (
                <>
                  <Col sm={12} md={6} lg={3} className='p-2 py-4 ' >
                    <div className='border border-1 rounded overflow-hidden shadow'>
                      <Card.Img variant="top" className='zoom-hover' src={ele.img} style={{ height: '180px' }} />
                      <Card.Body className='p-3'>
                        <Card.Title className='py-2 text-center'>{ele.title}</Card.Title>
                        <Nav.Link href='#'><Link to={`/prodcuts/${ele.id}`} className='text-black d-flex justify-content-center'>
                          <button className='btn col-6 btn-outline-primary py-2 mx-auto text-uppercase'>read more</button>
                        </Link></Nav.Link>
                      </Card.Body>
                    </div>
                  </Col>
                </>
              )
            })
          }
        </Row>
      </Container>
    </>
  )
}

export default Surgical_Pro