import React from 'react'
import Banner from '../components/Banner'
import Service from '../components/Service'
import About_copany from '../components/About_copany'
import Introducing from '../components/Introducing'
import Contact_from from '../components/Contact_from'
import Feature from '../components/Feature'
import Our_Ex from '../components/Our_Ex'
import Committee from '../components/Committee'
const Home = () => {
  return (
    <>
      <Banner />
      <Service />
      <About_copany />
      <Introducing />
      <Committee/>
      <Contact_from/>
      
    </>
  )
}

export default Home
