import { Link } from "react-router-dom";
import Main_routes from "./allroutes/Main_routes";
import Header from "./components/Header";
import Header_contact from "./components/Header-contact";
import Footer from "./components/Footer";
import WhatsApp from "./components/WhatsApp";

function App() {

  return (
    <>
      <Header_contact />
      <Header />
      <Main_routes />
      <Footer />
      <WhatsApp/>
    </>
  );
}

export default App;
