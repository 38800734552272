import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import gif from '../assets/gif.mp4'
import owner from '../assets/ceo-img.jpg'
const About_copany = () => {
  return (
    <>
      <section className='bg-gray'>
        <Container className='p-5'>
          <Row className='align-items-center'>
            <Col sm={12} md={6} >
              <video autoPlay muted loop  className="col-12 wow animate__animated animate__fadeInLeft">
                <source class="col-12" src={gif} type="video/mp4"/>
              </video>
            </Col>
            <Col sm={12} md={6} className='col-12 wow animate__animated animate__fadeInRight'>
              <p className='text-uppercase ps-5 ms-3 m-0 position-relative fw-bold fs-4 text-blue'>about</p>
              <p className='text-uppercase m-0 position-relative fw-bold fs-4 text-primary'>agri<span style={{textShadow: '2px 2px 5px black'}} className='text-white fw-bold'> world </span><span className='text-success'>exim</span>
              </p>
              <p className='fw-semobold fs-5 py-4'>Examining the history of the world, it is found that each country has its own unique characteristics, and each country is dependent on each other for different goods and services.So we are always ready to Supply every product manufactured in our country to the global market.  We do our best in the global demand and supply chain.
</p>              
              <div className='col-6'>
                <div className="row align-items-center">
                  <div className="col-4 ">
                    <img src={owner} alt="" className='rounded-circle' />
                  </div>
                  <div className="col-6">
                    <p className='m-0 text-uppercase'>jignesh</p>
                    <p className='m-0 text-muted text-uppercase'>( ceo )</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default About_copany