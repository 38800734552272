import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Choose_us = () => {
  return (
    <>
      <section className='bg-choose'>
        <Container className='p-5'>
          <Row className='align-items-center'>
            <Col sm={12} md={6}>
              <p className='wow animate__animated animate__fadeInLeft animate__faster text-uppercase m-0 position-relative fw-bold fs-4 text-primary'><span className='p-0 ps-md-5 text-blue fw-bold'>why</span> Choose Us<span className='about-content-bg position-absolute'></span></p>
              <p className='wow animate__animated animate__fadeInLeft animate__fast pt-3 ps-0 ps-md-5 fs-10'>We are vastly appreciated in the market due to our offered highly reliable and durable range of products in several specifications at most judicious rates.</p>
              <p className='wow animate__animated animate__fadeInLeft animate__slow ps-0 ps-md-5 fs-5 fw-semibold text-blue'>Some of the attributes that make us the most suitable choice of our clients are:</p>
              <ul className='wow animate__animated animate__fadeInLeft animate__slower p-0 ps-md-5'>
                <li><p><i className="text-danger bi bi-caret-right-fill"></i> Understanding Import-Export Dynamics</p></li>
                <li><p><i className="text-danger bi bi-caret-right-fill"></i> Legal and Regulatory Considerations</p></li>
                <li><p><i className="text-danger bi bi-caret-right-fill"></i> Market Research and Strategy</p></li>
                <li><p><i className="text-danger bi bi-caret-right-fill"></i> Supply Chain Management</p></li>
                <li><p><i className="text-danger bi bi-caret-right-fill"></i> Financial Management and Risk Mitigation</p></li>
                <li><p><i className="text-danger bi bi-caret-right-fill"></i> Cultivating International Partnerships</p></li>
                <li><p><i className="text-danger bi bi-caret-right-fill"></i> Timely delivery</p></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Choose_us