import React from 'react'
import About_copany from '../components/About_copany'
import Choose_us from '../components/Choose_us'
import Infrastructure from '../components/Infrastructure'
import about from '../assets/ab-u.png'
import About_slider from '../components/About_slider'

const About_Us = () => {
  return (
    <>
    <section>
      <img src={about} alt="" />
    </section>
    <About_copany/>
    <About_slider/>
    <Choose_us/>
    <Infrastructure/>
    </>
  )
}

export default About_Us