import React, { useEffect, useState } from 'react'
import Ginger_powder from '../assets/2148579397.jpg'
import Ginger from '../assets/2148799553.jpg'
import ragi from '../assets/ragi-seed.jpg'
import ragi_powder from '../assets/Ragi_power.jpeg'
import Peanut from '../assets/top-view-unshelled-peanuts-bowl-with-peeled-black-wooden-table.jpg'
import Peanutoil from '../assets/Peanutoil.jpeg'
import Turmeric from '../assets/401.jpg'
import Cardamom from '../assets/green-cardamom-wooden-spoon-light-green_145644-42031.jpg'
import Pearl from '../assets/bajra_flr1-1.jpg'
import Dehydrated  from '../assets/dehydrated-curry-leaves-powder.jpg'
import Gram  from '../assets/p11.jpg'
import Pigeon  from '../assets/p12.jpg'
import Pulses  from '../assets/p13.jpg'
import Sesame  from '../assets/9410.jpg'
import pigeon from '../assets/top-view-raw-uncooked-red-lentils-bowl-with-wooden-spoon-green-background-with-copy-space.jpg'
import Garlic from '../assets/1709822243682.jpg'
import Onion from '../assets/1709822060615.png'
import wheat from '../assets/wheat.jpg'
import { useParams } from 'react-router-dom'
import Banner from '../assets/sinlgletitle-bg.png'

const SinglePage = () => {

    let { id } = useParams();
    let [arr, setArr] = useState([])
    let [data, setdata] = useState([])
    let p1 = {
        id: 1,
        title: 'Ginger powder',
        img: Ginger_powder,
        Quantity_type: "g / kg",
        Form_Factor: 'powder',
        Container_Type: 'Box',
        prod_num: '09101210 ',
    }
    let p2 = {
        id: 2,
        title: 'Ginger',
        img: Ginger,
        Quantity_type: "g / kg",
        Form_Factor: 'Grain ',
        Container_Type: 'Box',
        prod_num: '09101110 ',
    }
    let p3 = {
        id: 3,
        title: 'Ragi Seed',
        img: ragi,
        Quantity_type: "g / kg",
        Form_Factor: 'Seed',
        Container_Type: 'Box / pouch',
        prod_num: '10082130',
    }
    let p4 = {
        id: 4,
        title: 'Ragi Product',
        img: ragi_powder,
        Quantity_type: "g/kg",
        Form_Factor: 'powder',
        Container_Type: 'Box',
        prod_num: '10082930 ',
    }
    let p5 = {
        id: 5,
        title: 'Peanut Seed',
        img: Peanut,
        Quantity_type: "g/kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / pouch',
        prod_num: '12024210 ',
    }
    let p6 = {
        id: 6,
        title: 'Peanut Product',
        img: Peanutoil,
        Quantity_type: "Litres",
        Form_Factor: 'oil',
        Container_Type: 'container / bottel',
        prod_num: '151790 ',
    }
    let p7 = {
        id: 7,
        title: 'Turmeric',
        img: Turmeric,
        Quantity_type: "g/kg",
        Form_Factor: 'Grain',
        Container_Type: 'containers / box',
        prod_num: '091030 ',
    }
    let p8 = {
        id: 8,
        title: 'Cardamom',
        img: Cardamom,
        Quantity_type: "Grams / kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / Count', 
        prod_num: '091030 ',
    }
    let p9 = {
        id: 9,
        title: 'Pearl Millet (Bajra)',
        img: Pearl,
        Quantity_type: "g/kg",
        Form_Factor: 'powder',
        Container_Type: 'Box / pouch',
        prod_num: '10082120 ',
    }
    let p10 = {
        id: 10,
        title: 'Dehydrated  powder',
        img: Dehydrated ,
        Quantity_type: "g/kg",
        Form_Factor: 'powder',
        Container_Type: 'Box / pouch',
        prod_num: '0712 ',
    }
    let p11 = {
        id: 11,
        title: 'Gram Lentils',
        img: Gram ,
        Quantity_type: "g/kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / pouch',
        prod_num: '10011 ',
    }
    let p12 = {
        id: 12,
        title: 'Pigeon Peas & Lentils',
        img: Pigeon ,
        Quantity_type: "g/kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / pouch',
        prod_num: '10011 ',
    }
    let p13 = {
        id: 13,
        title: 'Gram (Pulses)',
        img: Pulses ,
        Quantity_type: "g/kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / pouch',
        prod_num: '10011 ',
    }
    let p14 = {
        id: 14,
        title: 'Sesame (Oil Seed)',
        img: Sesame,
        Quantity_type: "Litres",
        Form_Factor: 'oil',
        Container_Type: 'container / bottel',
        prod_num: '9410 ',
    }
    let p15 = {
        id: 15,
        title: 'Pigeon Peas (Pulses)',
        img: pigeon,
        Quantity_type: "g / kg",
        Form_Factor: 'Pulses',
        Container_Type: 'container / bottel',
        prod_num: '9410 ',
    }
    let p16 = {
        id: 10,
        title: 'Garlic Powder',
        img: Garlic,
        Quantity_type: "g/kg",
        Form_Factor: 'Dehydrated ',
        Container_Type: 'Box / pouch',
        prod_num: '1709822243682 ',
    }
    let p17 = {
        id: 10,
        title: 'Onion Powder',
        img: Onion,
        Quantity_type: "g/kg",
        Form_Factor: 'Dehydrated ',
        Container_Type: 'Box / pouch',
        prod_num: '1709822060615 ',
    }
    let p18 = {
        id: 18,
        title: 'wheat',
        img: wheat,
        Quantity_type: "g/kg",
        Form_Factor: 'Grain',
        Container_Type: 'Box / bag',
        prod_num: '10082931 ',
      }
    useEffect(() => {
        setArr([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10,p11,p12,p13,p14,p15,p16,p17,p18])
    }, [])
    useEffect(() => {
        setdata(arr.filter((e) => e.id == id))
    }, [arr])

    return (
        <>
            {
                data.map((ele) => {
                    return (
                        <>
                            <section className='col-12'>
                                <img src={Banner} className='position-relative' />
                                <h1 className='position-absolute text-center start-center text-uppercase'>{ele.title}</h1>
                            </section>
                            <section>
                                <div className="container py-5">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-6">
                                            <img src={ele.img} alt={ele.title} className='shadow-lg rounded-2 wow animate__animated animate__fadeIn' />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <table className='col-12 wow animate__animated animate__fadeInRight'>
                                                <thead>
                                                    <tr>
                                                        <th className='col-5 text-center' colSpan={2}>{ele.title}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='col-5'>Quantity_type</td>
                                                        <td className='col-7'>{ele.Quantity_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Form_Factor</td>
                                                        <td className='col-7'>{ele.Form_Factor}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Container_Type</td>
                                                        <td className='col-7'>{ele.Container_Type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quantity_type</td>
                                                        <td className='col-7'>{ele.prod_num}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    )
                })
            }

            <section>

            </section>
        </>
    )
}

export default SinglePage