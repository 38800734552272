import React, { useEffect, useState } from 'react'
import Ginger_powder from '../assets/2148579397.jpg'
import Ginger from '../assets/2148799553.jpg'
import ragi from '../assets/ragi-seed.jpg'
import ragi_powder from '../assets/Ragi_power.jpeg'
import Peanut from '../assets/top-view-unshelled-peanuts-bowl-with-peeled-black-wooden-table.jpg'
import Peanutoil from '../assets/Peanutoil.jpeg'
import Turmeric from '../assets/401.jpg'
import Cardamom from '../assets/green-cardamom-wooden-spoon-light-green_145644-42031.jpg'
import Pearl from '../assets/bajra_flr1-1.jpg'
import Dehydrated from '../assets/dehydrated-curry-leaves-powder.jpg'
import Gram from '../assets/p11.jpg'
import Pigeon from '../assets/p12.jpg'
import Pulses from '../assets/p13.jpg'
import Sesame from '../assets/9410.jpg'
import pigeon from '../assets/top-view-raw-uncooked-red-lentils-bowl-with-wooden-spoon-green-background-with-copy-space.jpg'
import Garlic from '../assets/1709822243682.jpg'
import Onion from '../assets/1709822060615.png'
import wheat from '../assets/wheat.jpg'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import Banner from '../assets/sinlgletitle-bg.png'
import { Link } from 'react-router-dom'
const Prodcut = () => {

    let [arr, setArr] = useState([])
    let [data, setdata] = useState([])
    let p1 = {
        id: 1,
        title: 'Ginger powder',
        img: Ginger_powder,
        Quantity_type: "g / kg",
        Form_Factor: 'powder',
        Container_Type: 'Box',
        prod_num: '09101210 ',
    }
    let p2 = {
        id: 2,
        title: 'Ginger',
        img: Ginger,
        Quantity_type: "g / kg",
        Form_Factor: 'Grain ',
        Container_Type: 'Box',
        prod_num: '09101110 ',
    }
    let p3 = {
        id: 3,
        title: 'Ragi',
        img: ragi,
        Quantity_type: "g / kg",
        Form_Factor: 'Seed',
        Container_Type: 'Box / pouch',
        prod_num: '10082130',
    }
    let p4 = {
        id: 4,
        title: 'Ragi Product',
        img: ragi_powder,
        Quantity_type: "g/kg",
        Form_Factor: 'powder',
        Container_Type: 'Box',
        prod_num: '10082930 ',
    }
    let p5 = {
        id: 5,
        title: 'Peanut Seed',
        img: Peanut,
        Quantity_type: "g/kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / pouch',
        prod_num: '12024210 ',
    }
    let p6 = {
        id: 6,
        title: 'Peanut',
        img: Peanutoil,
        Quantity_type: "Litres",
        Form_Factor: 'oil',
        Container_Type: 'container / bottel',
        prod_num: '151790 ',
    }
    let p7 = {
        id: 7,
        title: 'Turmeric',
        img: Turmeric,
        Quantity_type: "g/kg",
        Form_Factor: 'Grain',
        Container_Type: 'containers / box',
        prod_num: '091030 ',
    }
    let p8 = {
        id: 8,
        title: 'Cardamom',
        img: Cardamom,
        Quantity_type: "Grams / kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / Count',
        prod_num: '091030 ',
    }
    let p9 = {
        id: 9,
        title: 'Pearl Millet (Bajra)',
        img: Pearl,
        Quantity_type: "g/kg",
        Form_Factor: 'powder',
        Container_Type: 'Box / pouch',
        prod_num: '10082120 ',
    }
    let p10 = {
        id: 10,
        title: 'Dehydrated  powder',
        img: Dehydrated,
        Quantity_type: "g/kg",
        Form_Factor: 'powder',
        Container_Type: 'Box / pouch',
        prod_num: '0712 ',
    }
    let p11 = {
        id: 11,
        title: 'Gram Lentils',
        img: Gram,
        Quantity_type: "g/kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / pouch',
        prod_num: '10011 ',
    }
    let p12 = {
        id: 12,
        title: 'Pigeon Peas & Lentils (Pulses)',
        img: Pigeon,
        Quantity_type: "g/kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / pouch',
        prod_num: '10011 ',
    }
    let p13 = {
        id: 13,
        title: 'Gram (Pulses)',
        img: Pulses,
        Quantity_type: "g/kg",
        Form_Factor: 'seed',
        Container_Type: 'Box / pouch',
        prod_num: '10011 ',
    }
    let p14 = {
        id: 14,
        title: 'Sesame (Oil Seed)',
        img: Sesame,
        Quantity_type: "Litres",
        Form_Factor: 'oil',
        Container_Type: 'container / bottel',
        prod_num: '9410 ',
    }
    let p15 = {
        id: 15,
        title: 'Pigeon Peas (Pulses)',
        img: pigeon,
        Quantity_type: "g / kg",
        Form_Factor: 'Pulses',
        Container_Type: 'container / bottel',
        prod_num: '9410 ',
    }
    let p16 = {
        id: 10,
        title: 'Garlic Powder',
        img: Garlic,
        Quantity_type: "g/kg",
        Form_Factor: 'Dehydrated ',
        Container_Type: 'Box / pouch',
        prod_num: '1709822243682 ',
    }
    let p17 = {
        id: 10,
        title: 'Onion Powder',
        img: Onion,
        Quantity_type: "g/kg",
        Form_Factor: 'Dehydrated ',
        Container_Type: 'Box / pouch',
        prod_num: '1709822060615 ',
    }
    let p18 = {
        id: 18,
        title: 'wheat',
        img: wheat,
        Quantity_type: "g/kg",
        Form_Factor: 'Grain',
        Container_Type: 'Box / bag',
        prod_num: '10082931 ',
      }
    useEffect(() => {
        setArr([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17,p18])
    }, [])
    useEffect(() => {
        setdata(arr)
    }, [arr])
    console.log(data);
    return (
        <>
            <section className='col-12'>
                <img src={Banner} className='position-relative' />
                <p className='fs-1 position-absolute text-center start-center text-uppercase'>our all prodcuts</p>
            </section>
            <Container>
                <Row className='justify-content-center'>
                    {
                        data.map((ele) => {
                            return (
                                <>
                                    <Col sm={12} md={6} lg={3} className='p-2 py-4 ' >
                                        <div className='border border-1 rounded overflow-hidden shadow'>
                                            <Card.Img variant="top" className='zoom-hover' src={ele.img} style={{ height: '180px' }} />
                                            <Card.Body className='p-3'>
                                                <Card.Title className='py-2 text-center text-capitalize'>{ele.title}</Card.Title>
                                                <Nav.Link href='#'>
                                                    <Link to={`/prodcuts/${ele.id}`} className='text-black d-flex justify-content-center'>
                                                        <button className='btn col-6 btn-outline-primary py-2 mx-auto text-uppercase'>read more</button>
                                                    </Link>
                                                </Nav.Link>
                                            </Card.Body>
                                        </div>
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
            </Container>
        </>
    )
}
export default Prodcut;