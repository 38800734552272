import React from 'react'
import accre from '../assets/acr.png'
import Certifacate from '../components/Certifacate'

const Accreditation = () => {
  return (
    <>
      <section>
        <img src={accre} alt="" />
      </section>
      <Certifacate/>
    </>
  )
}

export default Accreditation