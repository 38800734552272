import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import About_Us from '../pages/About_Us'
import Accreditation from '../pages/Accreditation'
import Beverages from '../pages/Beverages'
import Contact from '../pages/Contact'
import Surgical_Pro from '../pages/Surgical_Pro'
import Team from '../pages/Team'
import Trade_Fair from '../pages/Trade_Fair'
import SinglePage from '../components/SinglePage'
import Oil from '../pages/Oil'
import Prodcut from '../components/Prodcut'
import Prodcut1 from '../components/Prodcut1'
import Spice from '../pages/Spice'
import Other from '../components/Other'
const Main_routes = () => {
  return (
    <>
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/About_Us' element={<About_Us/>} />
        <Route path='/Accreditation' element={<Accreditation/>} />
        <Route path='/PULSES' element={<Beverages/>} />
        <Route path='/grain' element={<Spice/>} />
        <Route path='/oil' element={<Oil/>} />
        <Route path='/Contact' element={<Contact/>} />
        <Route path='/all_prodcuts' element={<Prodcut/>} />
        <Route path='/other' element={<Other/>} />
        <Route path='/Dehydrated' element={<Prodcut1/>} />
        <Route path='/Spices' element={<Surgical_Pro/>} />
        <Route path='/Team' element={<Team/>} />
        <Route path='/prodcuts/:id' element={<SinglePage/>} />
        <Route path='/Trade_Fair' element={<Trade_Fair/>} />
    </Routes>
    </>
  )
}

export default Main_routes