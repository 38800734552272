import React, { useState } from 'react'
const Trade_Fair = () => {

  let [nav, setnav] = useState('')
  return (
    <>
      <section>
        <div className="container py-5 pt-5 my-5">
          <ul class="nav nav-tabs justify-content-center border-0" id="myTab">
            <li class="nav-item" role="presentation">
              <button class="nav-link active fs-5 fw-bold text-uppercase rounded-0" id="home-tab" data-bs-toggle="tab"
                data-bs-target="#Gift">all</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link fs-5 fw-bold text-uppercase rounded-0" id="profile-tab" data-bs-toggle="tab"
                data-bs-target="#Flowering">GPBS 2020</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link fs-5 fw-bold text-uppercase rounded-0" id="prfile-tab" data-bs-toggle="tab"
                data-bs-target="#INDUS"> INDUS FOOD 2020</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link fs-5 fw-bold text-uppercase rounded-0" id="contact-tab" data-bs-toggle="tab"
                data-bs-target="#Bonsai">INDUS MIDDLE EAST 2020</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link fs-5 fw-bold text-uppercase rounded-0" id="disabled-tab" data-bs-toggle="tab"
                data-bs-target="#Desk">GULF FOOD 2020</button>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}

export default Trade_Fair