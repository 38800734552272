import React from 'react'
import { Button, Col, Row, Toast } from "react-bootstrap";
import { useState } from "react";
import what from '../assets/whatsapp.png'
const WhatsApp = () => {


    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);

    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);
    return (
        <>
            <Col md={6} className=" position-fixed z-index-9999">
                <Button onClick={toggleShowA} className="rounded-circle bg-success border-0">
                    <i class="bi bi-whatsapp text-white fs-2"></i>
                </Button>
                <Toast show={showA} onClose={toggleShowA} className="bg-success">
                    <Toast.Header>
                        <Row>
                            <Col sm={3} className="text-center">
                                <i class="bi bi-whatsapp text-white fs-2"></i>
                            </Col>
                            <Col sm={9}>
                                <p className="fs-5 m-0 text-white">Start a Conversation</p>
                                <p className="f-10 text-white">Hi! Click one of our member below to chat on WhatsApp</p>
                            </Col>
                        </Row>
                    </Toast.Header>
                    <Toast.Body className='bg-white'>
                        <p className="col-12 fs-10">The team typically replies in a few minutes.</p>
                        <a href="https://wa.me/918306083041?text=&amp;text=Hi! MR JIGNESH
 " target="_blank" className="d-flex p-2 justify-content-between border-0 bg-muted rounded">
                            <Col sm={9} className="d-flex align-items-center">
                                <img src={what} className="col-3" />
                                <p className="fw-semibold text-uppercase text-black fs-6">support team<br /><span className="fw-normal text-muted fs-10 me-4">support team</span></p>
                            </Col>
                            <Col sm={3}>
                                <i class="bi bi-whatsapp fs-4 text-success"></i>
                            </Col>
                        </a>
                    </Toast.Body>
                </Toast>
            </Col>
            {/* <Col md={6} className="">
                <Button onClick={toggleShowA} className="bg-success border-0 rounded-circle" style={{ width: '70px', height: '70px' }}>

                </Button>
                <Toast>
                    <Toast.Header show={showA} onClose={toggleShowA} >

                    </Toast.Header>
                    <Toast.Body className="px-3">
                        
                    </Toast.Body>
                </Toast>
            </Col> */}
            </>
    )
}

export default WhatsApp