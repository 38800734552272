import React, { useEffect, useState } from 'react'
import Dehydrated from '../assets/dehydrated-curry-leaves-powder.jpg'
import Garlic from '../assets/1709822243682.jpg'
import Onion from '../assets/1709822060615.png'
import Banner from '../assets/sinlgletitle-bg.png'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Prodcut1 = () => {

    let [arr, setArr] = useState([])
    let [data, setdata] = useState([])
    let p10 = {
        id: 10,
        title: 'Dehydrated  powder',
        img: Dehydrated,
        Quantity_type: "g/kg",
        Form_Factor: 'Dehydrated ',
        Container_Type: 'Box / pouch',
        prod_num: '0712 ',
    }
    let p16 = {
        id: 10,
        title: 'Garlic Powder',
        img: Garlic,
        Quantity_type: "g/kg",
        Form_Factor: 'Dehydrated ',
        Container_Type: 'Box / pouch',
        prod_num: '1709822243682 ',
    }
    let p17 = {
        id: 10,
        title: 'Onion Powder',
        img: Onion,
        Quantity_type: "g/kg",
        Form_Factor: 'Dehydrated ',
        Container_Type: 'Box / pouch',
        prod_num: '1709822060615 ',
    }
    useEffect(() => {
        setArr([p16, p17])
    }, [])
    useEffect(() => {
        setdata(arr)
    }, [arr])
    return (
        <>
            <section className='col-12'>
                <img src={Banner} className='position-relative' />
                <p className='position-absolute fs-1 text-center start-center text-uppercase'>our Dehydrated  prodcuts</p>
            </section>
            <Container>
                <Row className='justify-content-center'>
                    {
                        data.map((ele) => {
                            return (
                                <>
                                    <Col sm={12} md={6} lg={3} className='p-2 py-4 ' >
                                        <div className='border border-1 rounded overflow-hidden shadow'>
                                            <Card.Img variant="top" className='zoom-hover' src={ele.img} style={{ height: '180px' }} />
                                            <Card.Body className='p-3'>
                                                <Card.Title className='py-2 text-center'>{ele.title}</Card.Title>
                                                <Nav.Link href='#'>
                                                    <Link to={`/prodcuts/${ele.id}`} className='text-black d-flex justify-content-center'>
                                                        <button className='btn col-6 btn-outline-primary py-2 mx-auto text-uppercase'>read more</button>
                                                    </Link>
                                                </Nav.Link>
                                            </Card.Body>
                                        </div>
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
            </Container>
        </>
    )
}

export default Prodcut1