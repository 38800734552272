import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import gif from '../assets/gif-2.mp4'
const Introducing = () => {
    return (
        <>
            <section className='bg-gray'>
                <Container className='p-5'>
                    <Row className='align-items-center'>
                        <Col sm={12} md={6} className='wow animate__animated animate__fadeInLeft'>
                            <p className='text-uppercase m-0 position-relative fw-bold fs-4 text-primary'>Introducing<span style={{textShadow: '2px 2px 5px black'}}  className='text-white fw-bold'> our</span> <span className='text-success'> work</span>  
                                </p>
                                
                            <p className='fw-semobold fs-5 py-4'>We care our services to each client specifically who want to export their products worldwide</p>
                            <p>Our creativity is driven by depth study in an effort to give you the best result and position of your product successfully in international market.</p>
                            <p>We’re on a mission to start studying the exact market for your products worldwide and establish the product in appropriate market.
                            </p>
                            <p>We fuel your business by growing it globally through our expert team and by presenting the product visibility to the customers abroad.</p>
                        </Col>
                        <Col sm={12} md={6} >
                            <video autoPlay muted loop className="col-12 wow animate__animated animate__fadeInRight">
                                <source class="col-12" src={gif} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Introducing