
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Banner from '../assets/sinlgletitle-bg.png'
import Sesame from '../assets/9410.jpg'
import Peanut from '../assets/top-view-unshelled-peanuts-bowl-with-peeled-black-wooden-table.jpg'


const Oil = () => {


    let [arr, setArr] = useState([])
    let [data, setdata] = useState([])
    let p5 = {
        id: 5,
        title: 'Peanut',
        img: Peanut,
        Quantity_type: "g/kg",
        Form_Factor: 'Pulses',
        Container_Type: 'Box / pouch',
        prod_num: '12024210 ',
      }
    let p14 = {
        id: 14,
        title: 'Sesame (Oil Seed)',
        img: Sesame,
        Quantity_type: "Litres",
        Form_Factor: 'oil',
        Container_Type: 'container / bottel',
        prod_num: '9410 ',
    }
    useEffect(() => {
        setArr([p5, p14])
    }, [])
    useEffect(() => {
        setdata(arr)
    }, [arr])
    return (
        <>
            <section className='col-12'>
                <img src={Banner} className='position-relative' />
                <p className='fs-1 d-none d-md-block position-absolute text-center start-center text-uppercase'>our oil prodcuts</p>
            </section>
            <Container>
                <Row className='justify-content-center'>
                    {
                        data.map((ele) => {
                            return (
                                <>
                                    <Col sm={12} md={6} lg={3} className='p-2 py-4 ' >
                                        <div className='border border-1 rounded overflow-hidden shadow'>
                                            <Card.Img variant="top" className='zoom-hover' src={ele.img} style={{ height: '180px' }} />
                                            <Card.Body className='p-3'>
                                                <Card.Title className='py-2 text-center'>{ele.title}</Card.Title>
                                                <Nav.Link href='#'>
                                                    <Link to={`/prodcuts/${ele.id}`} className='text-black d-flex justify-content-center'>
                                                        <button className='btn col-6 btn-outline-primary py-2 mx-auto text-uppercase'>read more</button>
                                                    </Link>
                                                </Nav.Link>
                                            </Card.Body>
                                        </div>
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
            </Container>
        </>
    )
}

export default Oil