import React from 'react'
import owner from '../assets/Amit-Mulani-1.jpg'
import owner2 from '../assets/owner-2.jpg'
import wave from '../assets/wave_1.png'
import footer from '../assets/team-footer-bg.png'
const Committee = () => {
    return (
        <>
            <section class="bg- pt-5">
                <div class="container d-flex justify-content-center">
                    <div class="col-10  bg-gray p-0 py-5 p-md-5 shadow rounded">
                        <p className='text-uppercase text-center m-0 position-relative fw-bold fs-4 text-primary'>our<span style={{textShadow: '2px 2px 5px black'}} className='text-white fw-bold'> Managing</span><span className='text-success'> Committee</span> 
                            </p>
                        <div class="row justify-content-center">
                            <div class="col-12 col-md-6 rounded">
                                <div class="wow animate__animated animate__zoomIn col-9 mx-auto mt-5 border-0 shadow-team rounded-2 ">
                                    <div class="shadow rounded-circle m-4">
                                        <img src={owner} class="rounded-circle p-2 " alt="" />
                                    </div>
                                    <div class="card-body p-0 ">
                                        <h5 class="card-title text-center text-uppercase fw-bold">Mr. Jignesh Dholariya</h5>
                                        <p class="card-text text-center text-uppercase">( ceo ) </p>
                                        <img src={wave} class="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 rounded">
                                <div class="wow animate__animated animate__zoomIn col-9 mx-auto mt-5 border-0 shadow-team rounded-2 ">
                                    <div class="shadow rounded-circle m-4">
                                        <img src={owner2} class="rounded-circle p-2 " alt="" />
                                    </div>
                                    <div class="card-body p-0 ">
                                        <h5 class="card-title text-center text-uppercase fw-bold">Mr. Paras Kajavadra  </h5>
                                        <p class="card-text text-center text-uppercase text-capitalize">(Chief Marketing Officer) <br/>
(Co-Founder)</p>
                                        <img src={wave} class="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={footer} class="img-fluid w-100" alt="" />
            </section>
        </>
    )
}

export default Committee
