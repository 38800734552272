import React, { useEffect, useState } from 'react'
import Turmeric from '../assets/401.jpg'
import Pearl from '../assets/bajra_flr1-1.jpg'
import wheat from '../assets/wheat.jpg'
import ragi from '../assets/ragi-seed.jpg'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Banner from '../assets/sinlgletitle-bg.png'

const Spice = () => {


    let [arr, setArr] = useState([])
    let [data, setdata] = useState([])

    let p3 = {
        id: 3,
        title: 'Ragi',
        img: ragi,
        Quantity_type: "g / kg",
        Form_Factor: 'Pulses',
        Container_Type: 'Box / pouch',
        prod_num: '10082130',
      }
    let p7 = {
        id: 7,
        title: 'Turmeric',
        img: Turmeric,
        Quantity_type: "g/kg",
        Form_Factor: 'Grain',
        Container_Type: 'containers / box',
        prod_num: '091030 ',
    }
    let p9 = {
        id: 9,
        title: 'Pearl Millet (Bajra)',
        img: Pearl,
        Quantity_type: "g/kg",
        Form_Factor: 'Grain',
        Container_Type: 'Box / pouch',
        prod_num: '10082120 ',
    }
    let p18 = {
        id: 18,
        title: 'wheat',
        img: wheat,
        Quantity_type: "g/kg",
        Form_Factor: 'Grain',
        Container_Type: 'Box / bag',
        prod_num: '10082931 ',
      }
    useEffect(() => {
        setArr([p3,p7, p9,p18])
    }, [])
    useEffect(() => {
        setdata(arr)
    }, [arr])

    return (
        <>
            <section className='col-12'>
                <img src={Banner} className='position-relative' />
                <p className='fs-1 d-none d-md-block position-absolute text-center start-center text-uppercase'>our Grain prodcuts</p>
            </section>
            <Container>
                <Row className='justify-content-center'>
                    {
                        data.map((ele) => {
                            return (
                                <>
                                    <Col sm={12} md={6} lg={3} className='p-2 py-4 ' >
                                        <div className='border border-1 rounded overflow-hidden shadow'>
                                            <Card.Img variant="top" className='zoom-hover' src={ele.img} style={{ height: '180px' }} />
                                            <Card.Body className='p-3'>
                                                <Card.Title className='py-2 text-center text-capitalize'>{ele.title}</Card.Title>
                                                <Nav.Link href='#'><Link to={`/prodcuts/${ele.id}`} className='text-black d-flex justify-content-center'>
                                                    <button className='btn col-6 btn-outline-primary py-2 mx-auto text-uppercase'>read more</button>
                                                </Link></Nav.Link>
                                            </Card.Body>
                                        </div>
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
            </Container>
        </>
    )
}

export default Spice