import React from 'react'
import gif from '../assets/gif-3.mp4'
import { Col, Container, Row } from 'react-bootstrap'
const Infrastructure = () => {
    return (
        <>
            <section>
                <Container className='p-5'>
                    <Row className='align-items-center'>
                        <Col sm={12} md={6} >
                            <video autoPlay muted loop className="col-12 wow animate__animated animate__fadeInLeft">
                                <source class="col-12" src={gif} type="video/mp4" />
                            </video>
                        </Col>
                        <Col sm={12} md={6} className='wow animate__animated animate__fadeInRight'>
                            <p className='text-uppercase text-center text-md-start m-0 position-relative fw-bold fs-4 text-primary'><span className='text-blue fw-bold'>our</span> Infrastructure
                                <span className='about-content-bg position-absolute'></span></p>
                            <p className='pt-3 text-center text-md-start fs-5'>As the world becomes increasingly interconnected, businesses of all sizes are recognizing the immense opportunities presented by engaging in import-export activities. Whether it's sourcing raw materials from overseas suppliers, accessing new markets for finished products, or capitalizing on specialized goods and services, the import-export industry offers a myriad of avenues for growth and expansion.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Infrastructure