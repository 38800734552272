import React from 'react'
import { Link } from 'react-router-dom'
import treade from '../assets/logo.png'
const Footer = () => {
    return (
        <>
            <footer class="footer-bg ">
                <div class="container">
                    <div class="row py-2 ">
                        <div class="col-12 col-md-6 col-lg-3 text-center text-lg-start mt-5 pt-lg-0">
                            <h3 class="fs-5  fw-bold">Quick Links</h3>
                            <ul className='ps-3'>
                                <li className='footer-hover pb-2 pt-2'><Link to='/' className='fw-semibold text-uppercase'>home</Link></li>
                                <li className='footer-hover pb-2'><Link to='/Team' className='fw-semibold text-uppercase'>our team</Link></li>
                                <li className='footer-hover pb-2'><Link to='/Trade_Fair' className='fw-semibold text-uppercase'>TRADE FAIR PARTICIPATION</Link></li>
                                <li className='footer-hover pb-2'><Link to='/Contact' className='fw-semibold text-uppercase'>contact us</Link></li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 text-center text-lg-start mt-5 pt-lg-0">
                            <h3 class="fs-5  fw-bold">Get in Touch</h3>
                            <ul class="ps- ps-md-3 py-3 list-unstyled">
                                <li class="py-1 fs-10"><a href="tel:+917567312338" className='col-4 text-dark'><i class="bi bi-telephone-fill"></i> +91 75673 12338</a>
                                </li>
                                <li class="py-1 fs-10"><a href="tel:+918306083041" className='col-4 text-dark'><i class="bi bi-telephone-fill"></i> +91 83060 83041  </a>
                                </li>
                                <li class="py-1 fs-10"> <a href="mailto:agriworldexim@gmail.com" className='col-4 text-dark'><i class="bi bi-envelope-fill"></i> agriworldexim@gmail.com</a></li>
                                <li class="py-1 fs-10"> <a href="https://maps.app.goo.gl/bhTA9Ea9T4ArWLvi6" className='col-4 text-dark '><i class="bi bi-geo-alt-fill"></i> 213, Hans Society, Opp.Krishna Township, Yamuna Chowk, Mota Varachha, Surat. Pin Code- 394 101</a></li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 text-center text-lg-start mt-5 pt-lg-0">
                            <h3 class="fs-5  fw-semibold">Product</h3>
                            <ul className='ps-3'>
                                <li className='footer-hover py-2 pt-2'><Link to='/Spices' className='fw-semibold text-uppercase'>Spices</Link></li>
                                <li className='footer-hover py-2'><Link to='/PULSES' className='fw-semibold text-uppercase'>PULSES</Link></li>
                                <li className='footer-hover py-2'><Link to='/grain' className='fw-semibold text-uppercase'>Grain</Link></li>
                                <li className='footer-hover py-2'><Link to='/oil' className='fw-semibold text-uppercase'>oil seed</Link></li>
                                <li className='footer-hover py-2'><Link to='/Dehydrated ' className='fw-semibold text-uppercase'>Dehydrated </Link></li>
                                <li className='footer-hover py-2'><Link to='/all_prodcuts' className='fw-semibold text-uppercase'>all prodcuts</Link></li>
                                <li className='footer-hover py-2'><Link to='/other' className='fw-semibold text-uppercase'>other prodcuts</Link></li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 text-center text-lg-start mt-5 pt-lg-0">
                            <img src={treade} alt="" />
                        </div>
                    </div>
                </div>
            </footer >
            <section className='bg-black'>
                <div className="container">
                    <div className="row justify-content-space-between">
                        <div className='col-12 col-md-6'>

                            <p class="fs-6 py-3 m-0 fs-10 text-white  ">© 2024 agri world exim. All Rights Reserved Developed by Customize Theme.</p>
                        </div>
                        <div className='col-12 col-md-6 d-flex justify-content-start justify-content-md-end'>
                            <i class="bi  px-2 py-3 bi-linkedin text-white"></i>
                            <a href="https://twitter.com/agriworldexim" className='py-3 text-white' target="_blank"><i class="bi  px-2 bi-twitter"></i></a>
                            <a href="https://www.instagram.com/Agriworld_exim" className='py-3 text-white' target="_blank"><i class="bi  px-2 bi-instagram"></i></a>
                            <a href="https://www.facebook.com/Agriworld_exim" className='py-3 text-white'><i class="bi  px-2 py-3 bi-facebook"></i></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer