import React, { useState } from 'react'
import s1 from '../assets/on-deman-asset-loading1.svg'
import s2 from '../assets/aBOUT-uS-300x253.png'
import s3 from '../assets/Untitled-1.png'
import s4 from '../assets/about.png'
import s5 from '../assets/cross-domain-copypaste.svg'
import s6 from '../assets/ceo-img.jpg'
import { Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
const Slider1 = () => {

    return (
        <>
            <div className="container">
                <div class="d-md-block d-none py-5">
                
                    <Swiper
                        slidesPerView={3}
                        centeredSlides={true}
                        spaceBetween={30}
                        loop={true}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                          }}
                        modules={[Autoplay]}
                        className="mySwiper text-white"
                    >
                        <SwiperSlide>
                            <div className='rounded p-3 bg-info'>
                                <img src={s1} alt="" className='col-12 ' />
                                <p className=' fs-5 fw-semibold '>Legal Status of Firm</p>
                                <p>Sole Proprietorship (Individual)</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='rounded p-3 bg-danger'>
                                <img src={s2} alt="" className='col-12 ' />
                                <p className=' fs-5 fw-semibold '>Payment Mode</p>
                                <p>L/C, T/T, D/A, D/P & CAD</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='rounded p-3 bg-warning'>
                                <p className=' fs-5 fw-semibold  fs-5'>Primary Competitive Advantage</p>
                                <p>Large Product Line <br />Good Financial Position & TQM <br />Large Production Capacity <br />Provide Customized Solutions</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='rounded p-3 bg-success'>
                                <img src={s3} alt="" className='col-12 ' />
                                <p className=' fs-5 fw-semibold '>Customized Packaging</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='rounded p-3 bg-dark'>
                                <img src={s4} alt="" className='col-12 ' />
                                <p className=' fs-5 fw-semibold '>Shipment Mode</p>
                                <p>By Shipping Line, By Air Cargo, By Road</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='rounded p-3 bg-info'>
                                <p className=' fs-5 fw-semibold '>Nature of Business</p>
                                <p>Exporter</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='rounded p-3 bg-success'>
                                <img src={s5} alt="" className='col-12 ' />
                                <p className=' fs-5 fw-semibold '>Additional Business</p>
                                <p>Manufacturer, Wholesaler, Trader, Service Provider, Importer</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='rounded p-3 bg-danger'>
                                <img src={s6} alt="" className='col-md-2 rounded-circle' />
                                <p className=' fs-5 fw-semibold  m-0'>Company ceo</p>
                                <p>Mr.Jignesh</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div class="d-md-none d-block">
                <Swiper>
                </Swiper>
            </div>
        </>
    )
}

export default Slider1